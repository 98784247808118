@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';

/*
	Editorial by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Features */

	.features {
		&:after {
			clear: both;
			content: '';
			display: block;
		}

		article {
			float: left;

			&:after {
				clear: both;
				content: '';
				display: block;
			}

			.icon {
				float: left;
			}
		}
	}

/* Posts */

	.posts {
		&:after {
			clear: both;
			content: '';
			display: block;
		}

		article {
			float: left;
		}
	}

/* Main */

	#main {
		padding-left: _size(sidebar-width-alt);
	}

/* Sidebar */

	#sidebar {
		position: absolute;
		top: 0;
		left: 0;
		min-height: 100%;
		width: _size(sidebar-width-alt);
	}

/* Banner */

	#banner {
		&:after {
			clear: both;
			content: '';
			display: block;
		}

		.content {
			float: left;
			padding-right: (_size(element-margin) * 2);
		}

		.image {
			float: left;
			margin-left: 0;
		}
	}